<template lang="pug">
button.purchase-step-btn.btn-hover.invert(@click="$emit('click')")
  div {{ $t('next') }}
  img(src="~@/assets/img/arrow-right.svg")
</template>

<script>
export default {
  name: "NextStepBtn",
}
</script>

<style lang="sass">
.purchase-step-btn
  height: 50px
  background: #EFDF00
  border-radius: 3px
  border: none
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25)
  display: flex
  align-items: center
  padding: 0 27px
  font-weight: bold
  img
    margin-left: 10px
    filter: invert(1)
</style>